export const employeeData = [
    {
    name: 'Bernado Galaviz',
    employId: 'FT-0001',
    eMail:'bernado@gmail.com',
    salary:40000,
    LeaveType:"Medical Leave",
    from:'27-feb-2019',
    to:'30-feb-2019',
    noOfDays:'2 Days',
    reason:'Going to hospital',
    roundColor:'green',
    leaveStatus:'Approved',
    mobile:'9876543210',
    strideCode:78,
    role:'Web Designer',
    avatar: 'https://www.hollywoodreporter.com/wp-content/uploads/2024/02/Avatar__The_Last_Airbender_n_S1_00_13_15_10RC.jpgAvatar__The_Last_Airbender_n_S1_00_13_15_10RC-H-2024.jpg?w=1296&h=730&crop=1&resize=681%2C383',
    designation: 'Web Designer',
    department: 'Engineering',
  },
  {
    name: 'John Doe',
    employId: 'FT-0002',
    eMail:'johndoe@gmail.com',
    salary:80000,
    LeaveType:"Medical Leave",
    from:'27-feb-2019',
    to:'30-feb-2019',
    noOfDays:'2 Days',
    reason:'Going to hospital',
    roundColor:'green',
    leaveStatus:'Approved',
    mobile:'9876543210',
    strideCode:88,
    role:'Web Developer',
    avatar: 'https://media.gettyimages.com/id/1354842602/photo/portrait-of-a-young-businesswoman-working-on-a-laptop-in-an-office.jpg?s=1024x1024&w=gi&k=20&c=hoAhrKHX9FVYs3EaxBgZ1WmIw4qi4cdc1JrB5xsXsuI=',
    designation: 'Web Developer',
    department: 'Engineering',
  },{
    name: 'John Smith',
    employId: 'FT-0003',
    eMail:'johnsmith@gmail.com',
    salary:20000,
    LeaveType:"Medical Leave",
    from:'27-feb-2019',
    to:'30-feb-2019',
    noOfDays:'2 Days',
    reason:'Going to hospital',
    leaveStatus:'New',
    roundColor:'blue',
    mobile:'9876543210',
    strideCode:82,
    role:'FrontEnd Developer',
    avatar: 'https://media.gettyimages.com/id/1369199360/photo/portrait-of-a-handsome-young-businessman-working-in-office.jpg?s=612x612&w=gi&k=20&c=BFc13n-vhT4GMd0ohRt0PFD3IzJ_Onf6nKDAObgh1CA=',
    designation: 'FrontEnd Developer',
    department: 'Engineering',
  },{
    name: 'Mike Litorus',
    employId: 'FT-0004',
    eMail:'mikelitorus@gmail.com',
    salary:25000,
    LeaveType:"Medical Leave",
    from:'27-feb-2019',
    to:'30-feb-2019',
    noOfDays:'2 Days',
    reason:'Going to hospital',
    leaveStatus:'Declined',
    roundColor:'red',
    mobile:'9876543210',
    strideCode:95,
    role:'BackEnd Developer',
    avatar: 'https://media.gettyimages.com/id/1299077582/photo/positivity-puts-you-in-a-position-of-power.jpg?s=612x612&w=gi&k=20&c=xBZQF30WrZt9HWBKseqwDmKotwJGWe8jkEI9cajC1RM=',
    designation: 'BackEnd Developer',
    department: 'Engineering',
  }
]