import React from 'react'

const UploadedProjectFiles = () => {
  return (
<div className="bg-white rounded-lg shadow-md border border-gray-300 p-4 pb-6 mb-4">
  <h2 className="text-lg font-bold mt-2 mb-2">Uploaded Files</h2>
  <ul className="space-y-2">
    <li className="flex items-center">
    <svg className='mb-8' width="20" height="25" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0734 4.32812C15.425 4.67969 15.6594 5.1875 15.6594 5.65625V18.625C15.6594 19.6797 14.8 20.5 13.7844 20.5H2.53438C1.47969 20.5 0.659375 19.6797 0.659375 18.625V2.375C0.659375 1.35938 1.47969 0.5 2.53438 0.5H10.5031C10.9719 0.5 11.4797 0.695312 11.8313 1.04688L15.0734 4.32812ZM13.6281 5.5L10.6594 2.53125V5.5H13.6281ZM2.53438 18.625H13.7844V7.375H9.72188C9.175 7.375 8.78438 6.98438 8.78438 6.4375V2.375H2.53438V18.625ZM12.3 13.0391C12.6906 13.4297 12.6125 14.5234 11.5969 14.5234C11.0109 14.5234 10.1516 14.2891 9.40938 13.8594C8.58906 14.0156 7.6125 14.3672 6.75313 14.6797C4.8 18.0391 3.66719 16.5156 3.78438 15.8906C3.94063 15.1094 4.99531 14.4844 5.77656 14.0938C6.16719 13.3516 6.75313 12.1016 7.14375 11.1641C6.87031 10.0703 6.71406 9.17188 6.87031 8.54688C7.06563 7.84375 8.39375 7.76562 8.55 8.78125C8.74531 9.36719 8.47188 10.3438 8.35469 10.9688C8.66719 11.7891 9.09688 12.375 9.76094 12.7656C10.4641 12.6875 11.8313 12.5703 12.3 13.0391ZM4.56563 16.0859C4.56563 16.0859 4.99531 15.8906 5.7375 14.7188C5.50313 14.9141 4.76094 15.5391 4.56563 16.0859ZM7.72969 8.625C7.65156 8.625 7.65156 9.67969 7.80781 10.2266C8.00313 9.875 8.04219 8.625 7.72969 8.625ZM6.79219 13.9766C7.41719 13.7031 8.12031 13.3906 8.94063 13.1953C8.47188 12.8828 8.08125 12.4141 7.76875 11.8281C7.49531 12.5312 7.18281 13.3125 6.79219 13.9766ZM11.9094 13.7812C12.0656 13.6641 11.8313 13.3516 10.4641 13.4688C11.7141 14.0156 11.9094 13.7812 11.9094 13.7812Z" fill="#7A7C7F"/>
    </svg>
      <div className="flex flex-col ml-4">
        <p className="text-gray-900 font-semibold">Hospital Management Details.xls</p>
        <p className="text-gray-500">John Doe May 31st at 6:53 PM</p>
        <p className="text-gray-500">Size: 14.8Mb</p>
      </div>
    </li>
    <hr />
    <li className="flex items-center">
    <svg className='mb-8' width="20" height="25" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0734 4.32812C15.425 4.67969 15.6594 5.1875 15.6594 5.65625V18.625C15.6594 19.6797 14.8 20.5 13.7844 20.5H2.53438C1.47969 20.5 0.659375 19.6797 0.659375 18.625V2.375C0.659375 1.35938 1.47969 0.5 2.53438 0.5H10.5031C10.9719 0.5 11.4797 0.695312 11.8313 1.04688L15.0734 4.32812ZM13.6281 5.5L10.6594 2.53125V5.5H13.6281ZM2.53438 18.625H13.7844V7.375H9.72188C9.175 7.375 8.78438 6.98438 8.78438 6.4375V2.375H2.53438V18.625ZM12.3 13.0391C12.6906 13.4297 12.6125 14.5234 11.5969 14.5234C11.0109 14.5234 10.1516 14.2891 9.40938 13.8594C8.58906 14.0156 7.6125 14.3672 6.75313 14.6797C4.8 18.0391 3.66719 16.5156 3.78438 15.8906C3.94063 15.1094 4.99531 14.4844 5.77656 14.0938C6.16719 13.3516 6.75313 12.1016 7.14375 11.1641C6.87031 10.0703 6.71406 9.17188 6.87031 8.54688C7.06563 7.84375 8.39375 7.76562 8.55 8.78125C8.74531 9.36719 8.47188 10.3438 8.35469 10.9688C8.66719 11.7891 9.09688 12.375 9.76094 12.7656C10.4641 12.6875 11.8313 12.5703 12.3 13.0391ZM4.56563 16.0859C4.56563 16.0859 4.99531 15.8906 5.7375 14.7188C5.50313 14.9141 4.76094 15.5391 4.56563 16.0859ZM7.72969 8.625C7.65156 8.625 7.65156 9.67969 7.80781 10.2266C8.00313 9.875 8.04219 8.625 7.72969 8.625ZM6.79219 13.9766C7.41719 13.7031 8.12031 13.3906 8.94063 13.1953C8.47188 12.8828 8.08125 12.4141 7.76875 11.8281C7.49531 12.5312 7.18281 13.3125 6.79219 13.9766ZM11.9094 13.7812C12.0656 13.6641 11.8313 13.3516 10.4641 13.4688C11.7141 14.0156 11.9094 13.7812 11.9094 13.7812Z" fill="#7A7C7F"/>
    </svg>
    <div className="flex flex-col ml-4">
        <p className="text-gray-900 font-semibold">Hospital Management Details.xls</p>
        <p className="text-gray-500">John Doe May 31st at 6:53 PM</p>
        <p className="text-gray-500">Size: 14.8Mb</p>
      </div>
    </li>
  </ul>
</div>
  )
}

export default UploadedProjectFiles
